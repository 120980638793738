<!-- navbar menu -->
<nav class="navbar navbar-expand-lg fixed-top navbar-dark shadow-sm p-0">
  <div class="container-md">
    <!-- your logo here -->
    <a class="navbar-brand" href="#home">
      <img src="assets/imgs/tamil.png"
           alt="- Tamil Crush Elevate Your Dating"
           height="50">
    </a>
    <!-- mobile button navbar toggle -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- navigation links -->
    <div class="collapse navbar-collapse justify-content-end" id="navbar">
      <div class="navbar-nav align-items-center">
        <a class="nav-link" href="/#home">Home</a>
        <a class="nav-link" href="/#intro">How-it-works</a>
        <a class="nav-link" href="/#faq">FAQ</a>
        <a class="nav-link" routerLink="/terms">Terms</a>
        <a class="nav-link" routerLink="/privacy">Privacy</a>
        <!--        <a class="nav-link" href="#reviews">reviews</a>-->
        <!--        <a class="nav-link" href="#contact">-->
        <!--          <button id="btn-nav">Date Now</button>-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</nav>

<!-- main content -->
<div class="container my-5">
  <h1 class="text-center mb-4">Terms Of Use</h1>
  <div class="card">
    <div class="card-body">
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By downloading, accessing, or using the Tamil Crush (the "App"), you agree to be bound by these Terms
          of Use (the "Terms"). If you do not agree to these Terms, do not use the App. These Terms may be updated by
          Tamil Crush ("we," "us," or "our") from time to time, and your continued use of the App constitutes acceptance
          of any changes.</p>
      </section>
      <section>
        <h2>2. Eligibility</h2>
        <p>You must be at least 18 years old to use the App. By using the App, you represent and warrant that you are at
          least 18 years old and that you have the right, authority, and capacity to enter into these Terms and to
          comply with all of the terms and conditions herein.</p>
      </section>
      <section>
        <h2>3. Account Registration</h2>
        <p>To use the App, you must create an account and provide accurate, current, and complete information. You are
          responsible for maintaining the confidentiality of your account login information and are fully responsible
          for all activities that occur under your account. You agree to notify us immediately of any unauthorized use
          of your account or any other breach of security.</p>
      </section>
      <section>
        <h2>4. User Conduct</h2>
        <p>You agree to use the App in a manner consistent with any and all applicable laws and regulations. You will
          not:</p>
        <ul>
          <li>Post, upload, or distribute any content that is defamatory, obscene, infringing, harassing, violent, or
            otherwise objectionable.
          </li>
          <li>Use the App for any fraudulent or illegal purpose.</li>
          <li>Impersonate any person or entity or misrepresent your affiliation with a person or entity.</li>
          <li>Collect or store personal data about other users without their consent.</li>
          <li>Engage in any conduct that restricts or inhibits any other user from using or enjoying the App.</li>
        </ul>
      </section>
      <section>
        <h2>5. Intellectual Property</h2>
        <p>The App and its content, including but not limited to text, graphics, images, logos, and software, are the
          property of Tamil Crush or its licensors and are protected by copyright, trademark, and other intellectual
          property laws. You may not reproduce, distribute, modify, or create derivative works of the App or its content
          without our prior written consent.</p>
      </section>
      <section>
        <h2>6. Disclaimers</h2>
        <p>The App is provided on an "as is" and "as available" basis. We do not warrant that the App will be
          uninterrupted or error-free. We make no representations or warranties of any kind, express or implied, as to
          the operation of the App or the information, content, or materials included in the App. To the full extent
          permissible by applicable law, we disclaim all warranties, express or implied, including but not limited to
          implied warranties of merchantability and fitness for a particular purpose.</p>
      </section>
      <section>
        <h2>7. Limitation of Liability</h2>
        <p>In no event shall Tamil Crush, its affiliates, officers, directors, employees, agents, or licensors be liable
          for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with
          your use of the App, whether based on warranty, contract, tort (including negligence), statute, or any other
          legal theory, even if we have been advised of the possibility of such damages.</p>
      </section>
      <section>
        <h2>8. Indemnification</h2>
        <p>You agree to indemnify, defend, and hold harmless Tamil Crush, its affiliates, officers, directors,
          employees, agents, and licensors from and against any and all claims, damages, losses, liabilities, costs, and
          expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the App, your
          violation of these Terms, or your violation of any rights of another.</p>
      </section>
      <section>
        <h2>9. Governing Law</h2>
        <p>These Terms and your use of the App shall be governed by and construed in accordance with the laws of
          the country in which you reside, without regard to its conflict of laws principles.</p>
      </section>
      <section>
        <h2>10. Termination</h2>
        <p>We may terminate or suspend your account and access to the App at any time, without prior notice or
          liability, for any reason, including if you breach these Terms. Upon termination, your right to use the App
          will immediately cease.</p>
      </section>
      <section>
        <h2>11. Changes to This Terms and Conditions</h2>
        <p>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.</p>
        These terms and conditions are effective as of 2024-06-23
      </section>
<!--      <section>-->
<!--        <h2>11. Contact Information</h2>-->
<!--        <p>If you have any questions about these Terms, please contact us at [Contact Information].</p>-->
<!--      </section>-->
    </div>
  </div>
</div>


<app-footer></app-footer>
