<nav class="navbar navbar-expand-lg fixed-top navbar-dark shadow-sm p-0">
  <div class="container-md">
    <!-- your logo here -->
    <a class="navbar-brand" href="#home">
      <img src="assets/imgs/tamil.png"
           alt="- Tamil Crush Elevate Your Dating"
           height="50">
    </a>
    <!-- mobile button navbar toggle -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- navigation links -->
    <div class="collapse navbar-collapse justify-content-end" id="navbar">
      <div class="navbar-nav align-items-center">
        <a class="nav-link" href="/#home">Home</a>
        <a class="nav-link" href="/#intro">How-it-works</a>
        <a class="nav-link" href="/#faq">FAQ</a>
        <a class="nav-link" routerLink="/terms">Terms</a>
        <a class="nav-link" routerLink="/privacy">Privacy</a>
        <!--        <a class="nav-link" href="#reviews">reviews</a>-->
        <!--        <a class="nav-link" href="#contact">-->
        <!--          <button id="btn-nav">Date Now</button>-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</nav>

<div class="container mt-5">
  <h1 class="text-center mb-4">Privacy Policy</h1>
  <div class="card">
    <div class="card-body">
      <p><strong>Last Updated: 22 Jun 2024</strong></p>

      <section class="mb-5">
        <p>Feenixtek Inc. has built the Tamil Crush app as a Freemium app. This SERVICE is provided by Feenixtek Inc. at
          no
          cost and is intended for use as is. Tamil Crush may feature in-app purchases in later versions.</p>
        <p>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing and improving the Service. We will not
          use or share your information with anyone except as described in this Privacy Policy.
        </p>
        <p>
          The app does use third party services that may collect information used to identify you.
          Link to privacy policy of third party service providers used by the app
        </p>
        <ul>
          <!--          <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>-->
          <li><a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html" target="_blank">Apple App
            Store</a></li>
          <li><a href="https://policies.google.com/privacy?hl=en-US" target="_blank">Google Analytics</a></li>
          <li><a href="https://firebase.google.com/support/privacy" target="_blank">Firebase</a></li>
        </ul>
      </section>
      <section class="mb-5">
        <h2>1. Information We Collect</h2>
        <h5>Personal Data:</h5>
        <ul>
          <li><strong>Registration Information:</strong> Email address and phone number
          </li>
          <li><strong>Profile Information:</strong> By completing your profile, you provide additional information about
            yourself, such as your gender, interests, preferences, and approximate location. Some of this information
            may be considered sensitive or special in certain countries, including details about your sexual
            orientation, sexual life, health, racial or ethnic origins, religious beliefs, or political affiliations. By
            choosing to share this data, you consent to our use of it as described in this Privacy Policy.
          </li>
          <li><strong>Payment Information:</strong> Credit card details or other payment method information if you make
            purchases through our app.
          </li>
        </ul>
        <h5>Usage Data:</h5>
        <ul>
          <li><strong>Device Information:</strong> IP address, browser type, mobile network information, and device
            identifiers.
          </li>
          <li><strong>Log Data:</strong> Access times, pages viewed, interactions with our app, and error reports.</li>
        </ul>
        <h5>Location Data:</h5>
        <ul>
          <li><strong>Approximate Location Data:</strong> To enhance your experience by showing you nearby users.</li>
          <li><strong>Precise Location Data:</strong> With your permission, we can collect your device's geolocation
            (latitude and longitude). If you choose not to grant permission, you may not have access to features that
            depend on precise geolocation
          </li>
          <!--        </ul>-->
          <!--        <h5>Cookies and Tracking Technologies:</h5>-->
          <!--        <p>We use cookies and similar technologies to improve our services and your experience, including tracking your-->
          <!--          usage patterns and preferences.</p>-->
          <!--        <h5>Communications Data:</h5>-->
          <!--        <ul>-->
          <li><strong>Messages:</strong> Content of messages sent through our app, including text, images, and other
            media.
          </li>
        </ul>
      </section>

      <section class="mb-5">
        <h2>2. How We Use Your Information</h2>
        <ul>
          <li><strong>To Provide and Manage Services:</strong> Creating and managing your account, providing customer
            support, processing transactions, and facilitating matches.
          </li>
          <li><strong>To Personalize Your Experience:</strong> Showing you relevant matches, personalized content, and
            ads
            based on your preferences and usage patterns.
          </li>
          <li><strong>To Communicate with You:</strong> Sending you updates, newsletters, promotional materials, and
            responding to your inquiries.
          </li>
          <li><strong>To Improve Our Services:</strong> Analyzing usage patterns to enhance our app’s functionality,
            user
            experience, and security.
          </li>
          <li><strong>To Ensure Safety and Security:</strong> Monitoring and verifying accounts, preventing fraud, and
            enforcing our terms and policies.
          </li>
        </ul>
      </section>

      <section class="mb-5">
        <h2>3. Sharing Your Information</h2>
        <p>We do not sell or rent your personal data. We may share your information in the following circumstances:</p>
        <ul>
          <li><strong>With Other Users:</strong> Limited profile information to facilitate connections and interactions.
          </li>
          <li><strong>For Legal Reasons:</strong> If required by law, or to protect our rights, property, and safety, or
            that of our users or others.
          </li>
          <li><strong>In Business Transfers:</strong> If we undergo a merger, acquisition, or asset sale, your
            information
            may be transferred to the new entity.
          </li>
        </ul>
      </section>

      <section class="mb-5">
        <h2>4. Data Security</h2>
        <p>We use various security measures to protect your information, including encryption, secure servers, and
          access
          controls. However, no data transmission over the internet or storage system can be guaranteed to be 100%
          secure.</p>
      </section>

      <section class="mb-5">
        <h2>5. Your Rights</h2>
        <ul>
          <li><strong>Access and Update:</strong> You can access and update your personal information through your
            account
            settings.
          </li>
          <li><strong>Delete Account:</strong> You can delete your account at any time, which will remove your profile
            and
            data from our systems.
          </li>
          <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications by following the
            unsubscribe
            instructions in those messages.
          </li>
          <li><strong>Withdraw Consent:</strong> You have the right to withdraw your consent for us to process your
            information at any time.
          </li>
        </ul>
      </section>

      <section class="mb-5">
        <h2>6. Children's Privacy</h2>
        <p>Our app is not intended for individuals under the age of 18. We do not knowingly collect personal data from
          children under 18. If we become aware that a child under 18 has provided us with personal information, we will
          take steps to delete such data.</p>
      </section>

      <section class="mb-5">
        <h2>7. International Data Transfers</h2>
        <p>Your information may be transferred to, and maintained on, computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection laws may differ from those of your
          jurisdiction. We will take all steps reasonably necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy.</p>
      </section>

      <section class="mb-5">
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy
          Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy
          periodically for any changes. Your continued use of the app after any modifications indicates your acceptance
          of
          the new Privacy Policy.</p>
      </section>

      <section class="mb-5">
        <!--    <h2>9. Contact Us</h2>-->
        <!--    <p>If you have any questions about this Privacy Policy, please contact us at:</p>-->
        <!--    <ul>-->
        <!--      <li><strong>Email:</strong>-->
        <!--      <li><strong>Email:</strong> [Your Email Address]</li>-->
        <!--      <li><strong>Address:</strong> [Your Physical Address]</li>-->
        <!--    </ul>-->
      </section>

      <p>By using Tamil Crush, you agree to the terms of this Privacy Policy. If you do not agree with any part
        of this Privacy Policy, you must discontinue use of the app.</p>

    </div>
  </div>
</div>

<app-footer></app-footer>
