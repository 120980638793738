<!-- preload spinner -->
<div id="preload">
  <div class="spinner-grow" role="status">
    <span class="sr-only"></span>
  </div>
</div>

<!-- navbar menu -->
<nav class="navbar navbar-expand-lg fixed-top navbar-dark shadow-sm p-0">
  <div class="container-md">
    <!-- your logo here -->
    <a class="navbar-brand" href="#home">
      <img src="assets/imgs/tamil.png"
           alt="- Tamil Crush Elevate Your Dating"
           height="50">
    </a>
    <!-- mobile button navbar toggle -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- navigation links -->
    <div class="collapse navbar-collapse justify-content-end" id="navbar">
      <div class="navbar-nav align-items-center">
        <a class="nav-link" href="#home">Home</a>
        <a class="nav-link" href="#intro">How-it-works</a>
        <a class="nav-link" href="#faq">FAQ</a>
        <a class="nav-link" routerLink="/terms">Terms</a>
        <a class="nav-link" routerLink="/privacy">Privacy</a>

        <!--        <a class="nav-link" href="#reviews">reviews</a>-->
        <!--        <a class="nav-link" href="#contact">-->
        <!--          <button id="btn-nav">Date Now</button>-->
        <!--        </a>-->
      </div>
    </div>
  </div>
</nav>

<!-- main content -->
<main>
  <!-- hero section -->
  <section class="home-section">
    <div class="container-xl">
      <div class="row justify-content-center align-items-center">
        <!-- hero text -->
        <div class="col-lg-6 col-md-7">
          <h3 data-aos="fade-down">Tamil Crush</h3>
          <!--          <h1 class="text-dark mb-0" data-aos="fade-down" data-aos-delay="100">Discover Love.</h1>-->
          <h1 class="mb-0" data-aos="fade-down" data-aos-delay="250">Your Journey to Meaningful </h1>
          <h1 class="text-dark" data-aos="fade-down" data-aos-delay="300">Connections Starts Here</h1>
          <div class="btn-area download">
            <a href="https://testflight.apple.com/join/Oqt6V3Xi" target="_blank"><img src="assets/imgs/appstore.svg" alt="app store"/></a>
            <a href="#"><img src="assets/imgs/play-store-1.png" alt="app store" style="max-width: 120px"/></a>
            <!--                <a href="#"><img src="assets/imgs/play-store.png" alt="play store"/></a>-->

          </div>

        </div>

        <!-- hero image -->
        <div class="col-md-3">
          <img class="img-fluid hero" src="assets/imgs/hero.webp"
               alt="- Tamil Crush Elevate Your Dating"
               data-aos="fade-down" data-aos-delay="500">
        </div>
        <!-- scroll down -->
        <a href="#intro" id="goDown">
          <svg width="20" fill="#B7115EFF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16.4">
            <path
              d="M14.6,7.9c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6V1c0-0.6-0.5-1-1-1s-1,0.5-1,1v11.5L1.9,7.9c-0.4-0.4-1-0.4-1.4,0C0,8.3,0,8.9,0.4,9.3l7.1,7.1l7.1-7.1C15,8.9,15,8.3,14.6,7.9z"/>
          </svg>
        </a>
      </div>
    </div>
  </section>

  <!-- intro section -->
  <section id="intro" class="bg-primary-2 pt-0 home-section">
    <!-- svg start section shape -->
    <svg preserveAspectRatio="none" width="100%" class="bg-arrow" viewBox="0 0 1200 100"
         xmlns="http://www.w3.org/2000/svg">
      <polygon fill="#fff" points="600 100 0 0 1200 0 600 100"></polygon>
    </svg>

    <div class="container-md pt-fix">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12">
          <!-- big card -->
          <div class="card-intro">
            <!--            <h3 data-aos="fade-down">Discover your soulmate</h3>-->
            <h1 data-aos="fade-down" data-aos-delay="100">How It Works</h1>
            <p class="pt-2" data-aos="fade-down" data-aos-delay="250">Download our app to get started.</p>
            <!-- benefit list -->
            <ul id="benefit" class="row">
              <!-- benefit item -->
              <li class="col-md-4" data-aos="fade-down" data-aos-delay="300">
                <span class="numb">1</span>
                <h2>Create A Profile</h2>
                <p>Create your profile in seconds with our easy sign-up. Don't forget to add a photo!</p>
              </li>

              <!-- benefit item -->
              <li class="col-md-4" data-aos="fade-down" data-aos-delay="350">
                <span class="numb">2</span>
                <h2>Find your crush</h2>
                <p>Find your crush with ease using a range of preferences and settings.</p>
              </li>
              <!-- benefit item -->
              <li class="col-md-4" data-aos="fade-down" data-aos-delay="400">
                <span class="numb">3</span>
                <h2>Break the ice</h2>
                <p>Send a message or interest to start communicating. It's your time to shine.</p>
              </li>
            </ul>
            <!-- button -->
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- svg end section shape -->
  <!--  <svg preserveAspectRatio="none" width="100%" class="bg-arrow" viewBox="0 0 1200 100"-->
  <!--       xmlns="http://www.w3.org/2000/svg">-->
  <!--    <polygon fill="#ff7b7b" points="600 100 0 0 1200 0 600 100"></polygon>-->
  <!--  </svg>-->

  <!-- faq section -->
  <section class="home-section" id="faq">
    <div class="container-md">
      <div class="row justify-content-center align-items-top">
        <!-- title box -->
        <div class="title-box">
          <h1 data-aos="fade-down">Faqs</h1>
          <p data-aos="fade-down" data-aos-delay="100">Have a question? Check out our frequently asked questions to find
            your answer.</p>
        </div>
        <!-- faq left container -->
        <div class="col-lg-6">
          <div class="accordion" id="faqLeft">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                  1. How do I get started on Tamil Crush?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                   data-bs-parent="#faqLeft">
                <div class="accordion-body">
                  Getting started is easy! Simply sign up, create your profile, and start exploring potential matches.
                  Our user-friendly interface ensures a smooth onboarding process.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  2. Is my information secure on Tamil Crush?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                   data-bs-parent="#faqLeft">
                <div class="accordion-body">
                  Absolutely. We prioritize your privacy and employ advanced security measures to safeguard your data.
                  Your journey on Tamil Crush is not just about finding love but doing so with confidence.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  3. Can I customize my profile on Tamil Crush?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                   data-bs-parent="#faqLeft">
                <div class="accordion-body">
                  Yes, personalization is key! Tailor your profile to reflect your unique personality, interests, and
                  preferences. Stand out and let your authenticity shine.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- faq right container  -->
        <div class="col-lg-6">
          <div class="accordion" id="faqRight">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  4. How does Tamil Crush facilitate meaningful connections?
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                   data-bs-parent="#faqRight">
                <div class="accordion-body">
                  Tamil Crush utilizes advanced matchmaking algorithms that consider your preferences, ensuring you
                  connect
                  with individuals who share your values and interests. It's not just about dating; it's about finding
                  compatibility and building something lasting.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  5. What makes Tamil Crush different from other dating platforms?
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                   data-bs-parent="#faqRight">
                <div class="accordion-body">
                  Tamil Crush goes beyond the typical dating experience. With a focus on creating genuine connections,
                  we
                  provide a space where users can explore, communicate, and build relationships in a meaningful way.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix"
                        aria-expanded="true" aria-controls="collapseSix">
                  6. Is customer support available if I have questions or concerns?
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingSix"
                   data-bs-parent="#faqRight">
                <div class="accordion-body">
                  Absolutely! Our dedicated support team is ready to assist you with any inquiries or challenges you may
                  encounter on your journey with Tamil Crush. Your satisfaction is our priority.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- reviews section -->
  <!-- svg end section shape -->
  <!--  <svg preserveAspectRatio="none" width="100%" class="bg-arrow" viewBox="0 0 1200 100"-->
  <!--       xmlns="http://www.w3.org/2000/svg">-->
  <!--    <polygon fill="#ff7b7b" points="600 100 0 0 1200 0 600 100"></polygon>-->
  <!--  </svg>-->

  <!-- contact section -->
  <!--  <section class="home-section" id="contact">-->
  <!--    <div class="container-md">-->
  <!--      <div class="row justify-content-center align-items-top">-->
  <!--        &lt;!&ndash; title box &ndash;&gt;-->
  <!--        <div class="title-box">-->
  <!--          <h1 data-aos="fade-down">Can we help you?</h1>-->
  <!--          &lt;!&ndash;          <p data-aos="fade-down" data-aos-delay="100">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>&ndash;&gt;-->
  <!--        </div>-->
  <!--        &lt;!&ndash; benefit list &ndash;&gt;-->

  <!--        &lt;!&ndash; form container &ndash;&gt;-->
  <!--        <div class="col-lg-8 offset-lg-1 col-md-10 mt-sm-4r">-->
  <!--          &lt;!&ndash; contact form &ndash;&gt;-->
  <!--          <form method="post" action="./php/contactform.php">-->
  <!--            &lt;!&ndash; input item &ndash;&gt;-->
  <!--            <div class="form-group">-->
  <!--              <label for="name">Name</label>-->
  <!--              <input type="text" class="form-control" id="name" placeholder="Enter name">-->
  <!--            </div>-->
  <!--            &lt;!&ndash; input item &ndash;&gt;-->
  <!--            <div class="form-group">-->
  <!--              <label for="email">Email address</label>-->
  <!--              <input type="email" class="form-control" id="email" placeholder="Enter email">-->
  <!--            </div>-->
  <!--            &lt;!&ndash; input item &ndash;&gt;-->
  <!--            <div class="form-group">-->
  <!--              <label for="message">Enter message</label>-->
  <!--              <textarea name="message" id="message" rows="8"></textarea>-->
  <!--            </div>-->
  <!--            &lt;!&ndash; input item &ndash;&gt;-->
  <!--            <div class="form-check">-->
  <!--              <input type="checkbox" class="form-check-input" id="agree">-->
  <!--              <label class="form-check-label" for="agree">I agree the privacy policy</label>-->
  <!--            </div>-->
  <!--            &lt;!&ndash; submit button &ndash;&gt;-->
  <!--            <button type="submit" class="btn">Submit Now</button>-->
  <!--          </form>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
</main>

<!-- svg start shape footer -->
<svg preserveAspectRatio="none" width="100%" class="bg-arrow" viewBox="0 0 1200 100" xmlns="http://www.w3.org/2000/svg">
  <polygon fill="#B7115EFF" points="600 0 0 100 1200 100 600 0"></polygon>
</svg>
<!-- footer -->
<footer>
  <!-- footer container -->
  <div class="container-md">
    <div class="row flex justify-content-center">
      <div class="col-md-2">
        <!-- your logo here -->
        <img src="assets/imgs/logo.png"
             alt="- Tamil Crush Elevate Your Dating" height="135">
      </div>
      <!-- footer widget -->
      <div class="col-md-10">
        <ul class="social">
          <li><a class="nav-link" routerLink="/terms">Terms Of Use</a></li>
          <li><a class="nav-link" routerLink="/privacy">Privacy Policy</a></li>
          <!--          <li><a class="nav-link" routerLink="/terms">About Us</a></li>-->
        </ul>
      </div>
      <!-- footer widget -->
      <!--      <div class="col-md-3">-->
      <!--        <p><strong>Social</strong></p>-->
      <!--        &lt;!&ndash; social links &ndash;&gt;-->
      <!--        <ul class="social">-->
      <!--          &lt;!&ndash; contact item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Gmail" href="tel:">-->
      <!--              <i class="fa-solid fa-phone"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; contact item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Gmail" href="mailto:">-->
      <!--              <i class="fa-solid fa-envelope"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Whatsapp" href="#">-->
      <!--              <i class="fa-brands fa-whatsapp"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Instagram" href="#">-->
      <!--              <i class="fa-brands fa-instagram"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--          &lt;!&ndash; social item &ndash;&gt;-->
      <!--          <li>-->
      <!--            <a title="Twitter" href="#">-->
      <!--              <i class="fa-brands fa-twitter"></i>-->
      <!--            </a>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
    </div>
  </div>
  <!-- credits -->
  <div class="pt-4">&copy; Tamil Crush <span id="date"></span></div>
</footer>


<!-- scrool to top button -->
<a id="toTop" title="Torna su">
  <i class="fa-solid fa-angle-up"></i>
</a>

